import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Helmet } from "react-helmet";
import { DropdownContext } from "./DropdownContext";
import { cacheBuster } from "./config";

function NewsChannels() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dropdownContext = useContext(DropdownContext);

  if (!dropdownContext) {
    throw new Error("DropdownContext must be used within DropdownProvider");
  }

  const { selectedOption } = dropdownContext;

  // Add new logo name variable here at the end after "ddnews", For eg: "newlogoname",
  const [tabs] = useState([
    "aajtak",
    "abpnews",
    "cnbcawaaz",
    "etnowswadesh",
    "indiatv",
    "ndtvindia",
    "news18india",
    "news24",
    "republicbharat",
    "tv9bharatvarsh",
    "zeebusiness",
    "zeenews",
    "timesnownavbharat",
    "ddnews",
  ]);

  const [selectedHindiTab, setSelectedHindiTab] = useState<string>(
    () => localStorage.getItem("selectedHindiTab") || "aajtak"
  );

  const handleTabClick = (channelKey: string) => {
    setSelectedHindiTab(channelKey);
    localStorage.setItem("selectedHindiTab", channelKey);
  };

  useEffect(() => {
    const savedTab = localStorage.getItem("selectedHindiTab");
    if (savedTab) {
      setSelectedHindiTab(savedTab);
    }
  }, []);

  const [visibleTabs, setVisibleTabs] = useState(tabs);
  // Add channel name in "" which you want to hide with comma separated. For eg: const hiddenTabs: any = ["aajtak", "abpnews"];
  const hiddenTabs: any = ["news24"];

  useEffect(() => {
    const updatedVisibleTabs = visibleTabs.filter(
      (tab) => !hiddenTabs.includes(tab)
    );
    setVisibleTabs(updatedVisibleTabs);

    if (hiddenTabs.includes(selectedHindiTab)) {
      const nextVisibleTab = updatedVisibleTabs[0];
      if (nextVisibleTab) {
        setSelectedHindiTab(nextVisibleTab);
      }
    }
  }, []);

  /*****  To add new logo add this line with new logo name start ******/
  const aajtak = `/assets/channel-logos/aajtak.png?v=${cacheBuster}`;
  /*****  To add new logo add this line with new logo name end ******/
  const abpnews = `/assets/channel-logos/abpnews.png?v=${cacheBuster}`;
  const cnbcawaaz = `/assets/channel-logos/cnbcawaaz.png?v=${cacheBuster}`;
  const etnowswadesh = `/assets/channel-logos/etnowswadesh.png?v=${cacheBuster}`;
  const indiatv = `/assets/channel-logos/indiatv.png?v=${cacheBuster}`;
  const ndtvindia = `/assets/channel-logos/ndtvindia.png?v=${cacheBuster}`;
  const news18india = `/assets/channel-logos/news18india.png?v=${cacheBuster}`;
  const news24 = `/assets/channel-logos/news24live.png?v=${cacheBuster}`;
  const republicbharat = `/assets/channel-logos/republicbharat.png?v=${cacheBuster}`;
  const tv9bharatvarsh = `/assets/channel-logos/tv9bharatvarsh.png?v=${cacheBuster}`;
  const zeebusiness = `/assets/channel-logos/zeebusiness.png?v=${cacheBuster}`;
  const zeenews = `/assets/channel-logos/zeenews.png?v=${cacheBuster}`;
  const timesnownavbharat = `/assets/channel-logos/timesnownavbharat.png?v=${cacheBuster}`;
  const ddnews = `/assets/channel-logos/ddnews24x7.png?v=${cacheBuster}`;

  const imagesToPreload = [
    aajtak,
    abpnews,
    cnbcawaaz,
    etnowswadesh,
    indiatv,
    ndtvindia,
    news18india,
    news24,
    republicbharat,
    tv9bharatvarsh,
    zeebusiness,
    zeenews,
    timesnownavbharat,
    ddnews,
  ];

  imagesToPreload.forEach((src) => {
    const img = new Image();
    img.src = src;
  });

  return (
    <>
      <div className="Apps">
        <Helmet>
          <title>
            Watch Latest Hindi News Live: Aaj Tak, ABP News, Zee News & More
          </title>
          <meta
            name="description"
            content="Stay updated with the latest Hindi news, breaking stories, and top news from Aaj Tak, ABP News, Zee News, and more. Get live updates on politics and entertainment."
          />
          <meta
            name="keywords"
            content="latest Hindi news, today top news Hindi, latest news in Hindi today, breaking Hindi news, top Hindi news, latest Hindi news updates, Aaj Tak Live, ABP News Live, Zee News Live, India TV Live, News18 India Live, Republic Bharat Live, NDTV India Live, TV9 Bharatvarsh Live, Zee Business Live, CNBC Awaaz Live, ET Now Swadesh, News24 Live"
          />
        </Helmet>
        <Header />

        {selectedOption === "Hindi" && (
          <>
            <div className="logo-grid">
              <div className="tabs">
                {/******  To add new logo copy paste this div(from line number 135 to 154) and change channel name wherever necessary and change the order start *******/}
                {visibleTabs.includes("aajtak") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "aajtak" ? "active" : ""
                    } order-1`}
                    onClick={() => handleTabClick("aajtak")}
                  >
                    {/**** When you add new logo change the src={aajtak} to src={newlogoname} in below div start ****/}
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={aajtak}
                        alt="Aaj Tak Live"
                      />
                    </div>
                    {/**** When you add new logo change the src={aajtak} to src={newlogoname} in below div end ****/}
                  </div>
                )}
                {/******  To add new logo copy paste this div and change channel name wherever necessary and change the order end *******/}

                {visibleTabs.includes("abpnews") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "abpnews" ? "active" : ""
                    } order-2`}
                    onClick={() => handleTabClick("abpnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={abpnews}
                        alt="ABP News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zeenews") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "zeenews" ? "active" : ""
                    } order-3`}
                    onClick={() => handleTabClick("zeenews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeenews}
                        alt="Zee News Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("indiatv") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "indiatv" ? "active" : ""
                    } order-4`}
                    onClick={() => handleTabClick("indiatv")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={indiatv}
                        alt="India TV Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news18india") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "news18india" ? "active" : ""
                    } order-5`}
                    onClick={() => handleTabClick("news18india")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news18india}
                        alt="News18 India Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("republicbharat") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "republicbharat" ? "active" : ""
                    } order-6`}
                    onClick={() => handleTabClick("republicbharat")}
                  >
                    <div className="image-container">
                      <img
                        rel="preload"
                        height="80"
                        width="80"
                        src={republicbharat}
                        alt="Republic Bharat Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("ndtvindia") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "ndtvindia" ? "active" : ""
                    } order-7`}
                    onClick={() => handleTabClick("ndtvindia")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ndtvindia}
                        alt="NDTV India Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("tv9bharatvarsh") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "tv9bharatvarsh" ? "active" : ""
                    } order-8`}
                    onClick={() => handleTabClick("tv9bharatvarsh")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={tv9bharatvarsh}
                        alt="TV9 Bharatvarsh Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("zeebusiness") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "zeebusiness" ? "active" : ""
                    } order-9`}
                    onClick={() => handleTabClick("zeebusiness")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={zeebusiness}
                        alt="Zee Business Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("cnbcawaaz") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "cnbcawaaz" ? "active" : ""
                    } order-10`}
                    onClick={() => handleTabClick("cnbcawaaz")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={cnbcawaaz}
                        alt="CNBC Awaaz Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("etnowswadesh") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "etnowswadesh" ? "active" : ""
                    } order-11`}
                    onClick={() => handleTabClick("etnowswadesh")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={etnowswadesh}
                        alt="ET Now Swadesh Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("news24") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "news24" ? "active" : ""
                    } order-12`}
                    onClick={() => handleTabClick("news24")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={news24}
                        alt="News24 Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("timesnownavbharat") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "timesnownavbharat" ? "active" : ""
                    } order-13`}
                    onClick={() => handleTabClick("timesnownavbharat")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={timesnownavbharat}
                        alt="Times Now Navbharat Live"
                      />
                    </div>
                  </div>
                )}

                {visibleTabs.includes("ddnews") && (
                  <div
                    className={`tab-item ${
                      selectedHindiTab === "ddnews" ? "active" : ""
                    } order-14`}
                    onClick={() => handleTabClick("ddnews")}
                  >
                    <div className="image-container">
                      <img
                        height="80"
                        width="80"
                        rel="preload"
                        src={ddnews}
                        alt="DD News 24x7 Live"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Display the selected YouTube video */}

            <div className="video-container">
              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary start  *******/}

              {selectedHindiTab === "aajtak" &&
                visibleTabs.includes("aajtak") && (
                  <>
                    <h1>Aaj Tak Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Nq2wYlWFucg?autoplay=1&v=${cacheBuster}`}
                      title="Aaj Tak Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {/******  To add new youtube video iframe copy paste this part and change channel name wherever necessary end *******/}

              {selectedHindiTab === "abpnews" &&
                visibleTabs.includes("abpnews") && (
                  <>
                    <h1>ABP News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/nyd-xznCpJc?si=wrKT7OmdJYIxZLV_&autoplay=1&v=${cacheBuster}`}
                      title="ABP News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "zeenews" &&
                visibleTabs.includes("zeenews") && (
                  <>
                    <h1>Zee News Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/WquRAK-XoV4?si=FH2jJlUD3-huuazh&autoplay=1&v=${cacheBuster}`}
                      title="Zee News Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "indiatv" &&
                visibleTabs.includes("indiatv") && (
                  <>
                    <h1>India TV Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Xmm3Kr5P1Uw?si=5sH6ECqUaqb2VcCG&autoplay=1&v=${cacheBuster}`}
                      title="India TV  Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "news18india" &&
                visibleTabs.includes("news18india") && (
                  <>
                    <h1>News18 India Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Io-G_aiF8HA?si=snQmSisMeZhoASGX&autoplay=1&v=${cacheBuster}`}
                      title="News18 India Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "republicbharat" &&
                visibleTabs.includes("republicbharat") && (
                  <>
                    <h1>Republic Bharat Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/ZwD3-hUQ3rk?si=4yw69F8fGIaH8ZrP&autoplay=1&v=${cacheBuster}`}
                      title="Republic Bharat Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "ndtvindia" &&
                visibleTabs.includes("ndtvindia") && (
                  <>
                    <h1>NDTV India Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-fQyqVqi7GI?si=M3tek148ZHNxQosB&autoplay=1&v=${cacheBuster}`}
                      title="NDTV India Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "tv9bharatvarsh" &&
                visibleTabs.includes("tv9bharatvarsh") && (
                  <>
                    <h1>TV9 Bharatvarsh Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/nSpwwcHVp80?si=rNkaG3HBVodT4GQK&autoplay=1&v=${cacheBuster}`}
                      title="TV9 Bharatvarsh Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "zeebusiness" &&
                visibleTabs.includes("zeebusiness") && (
                  <>
                    <h1>Zee Business Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/Of3n3GqqtY0?si=EkRES224ejfM0c3I&autoplay=1&v=${cacheBuster}`}
                      title="Zee Business Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "cnbcawaaz" &&
                visibleTabs.includes("cnbcawaaz") && (
                  <>
                    <h1>CNBC Awaaz Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/TD0A7fHAxKw?si=32gX-z97JZEZ7HS1&autoplay=1&v=${cacheBuster}`}
                      title="CNBC Awaaz Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "etnowswadesh" &&
                visibleTabs.includes("etnowswadesh") && (
                  <>
                    <h1>ET Now Swadesh Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/MTuqrLy--6g?si=B7XCpULIPFiT6oLQ&autoplay=1&v=${cacheBuster}`}
                      title="ET Now Swadesh Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "news24" &&
                visibleTabs.includes("news24") && (
                  <>
                    <h1>News24 Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/cypBrJzVaKY?si=NZV-zqBlaqO546Bp&autoplay=1&v=${cacheBuster}`}
                      title="News24 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "timesnownavbharat" &&
                visibleTabs.includes("timesnownavbharat") && (
                  <>
                    <h1>Times Now Navbharat Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/-72ex_Qd72w?si=tht1Mye08Se-SLIy&autoplay=1&v=${cacheBuster}`}
                      title="News24 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}

              {selectedHindiTab === "ddnews" &&
                visibleTabs.includes("ddnews") && (
                  <>
                    <h1>DD News 24x7 Live</h1>
                    <iframe
                      width="100%"
                      height="450px"
                      src={`https://www.youtube.com/embed/OjsE734aXyc?si=td4VEQHFMWxR-hQS&autoplay=1&v=${cacheBuster}`}
                      title="News24 Live"
                      loading="lazy"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </>
                )}
            </div>
          </>
        )}

        <p style={{ display: "none" }}>
          Stay up-to-date with the Hindilatest Hindi newsHindi and breaking
          stories across India with live streaming from top Hindi news channels.
          Whether you're looking for Hinditoday's top news in HindiHindi, live
          coverage of significant events, or the latest developments in
          business, we've got you covered. Watch HindiAaj Tak LiveHindi,
          HindiABP News LiveHindi, HindiZee News LiveHindi, and more, directly
          from our website. Stay informed about national and international
          events, entertainment, politics, sports, and business news in
          real-time, all in Hindi.
        </p>

        <p style={{ display: "none" }}>
          Our website provides a one-stop destination for Hinditoday’s top news
          in HindiHindi, including live updates from channels like HindiIndia TV
          LiveHindi, HindiNews18 India LiveHindi, HindiRepublic Bharat
          LiveHindi, and HindiNDTV India LiveHindi. If you're interested in
          business and financial news, tune into HindiZee Business LiveHindi,
          HindiCNBC Awaaz LiveHindi, or HindiET Now SwadeshHindi for expert
          analysis, stock market trends, and the latest business updates in
          Hindi. Get real-time information and never miss an important news
          event again.
        </p>
      </div>
      <Footer />
    </>
  );
}

export default NewsChannels;
